import Select from "react-select";

const FormSelect = ({
  id,
  value,
  options = [],
  multiple,
  handleOnChange = (key, value) => {},
  ...restProps
}) => {
  // removing bootstrap class
  restProps["className"] = undefined;

  const selectValue = multiple
    ? options.filter((opt) => value?.includes(opt.value))
    : options.find((opt) => opt.value === value);

  const getValue = (value) => {
    return multiple ? value?.map(({ value }) => value) : value;
  };

  return (
    <Select
      value={selectValue}
      options={options}
      isMulti={multiple}
      onChange={(value) => handleOnChange(id, getValue(value))}
      {...restProps}
    />
  );
};

export default FormSelect;
