import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Share, Smile } from "react-feather";
import EmojiPicker from "emoji-picker-react";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";

import {
  setForm,
  sendMessage,
  addConversationEmoji,
  addFile,
} from "@store/modules/interaction/actions";
import { useEffect } from "react";
import { useState } from "react";

const FooterChat = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { form, conversation } = useSelector((state) => state.interaction);

  const handleOnEmojiClick = ({ emoji }) => {
    dispatch(addConversationEmoji(emoji));
  };

  const handleSendMessage = () => {
    dispatch(sendMessage());
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file); // Do something with the file
    dispatch(addFile(file));
    handleSendMessage();
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    fileInputRef.current.value = "";
  };

  const popoverTop = (
    <Popover id="popover-positioned-top" title="Popover top">
      <EmojiPicker onEmojiClick={handleOnEmojiClick} />
    </Popover>
  );

  useEffect(() => {
    dispatch(setForm({ textMessage: "" }));
  }, [conversation]);

  return (
    <footer class="chat-footer">
      <div>
        <button
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover flex-shrink-0"
          onClick={handleClick}
        >
          <span className="icon">
            <Share />
          </span>
        </button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>

      <div className="input-group">
        <span className="input-affix-wrapper">
          <input
            disabled={
              form?.loadingSendMessage || form?.loadingCloseConversation
            }
            type="text"
            id="input_msg_send_chatapp"
            name="send-msg"
            className="input-msg-send form-control rounded-input"
            placeholder="Escreva sua mensagem..."
            value={form?.textMessage}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              dispatch(setForm({ textMessage: e.target.value }));
            }}
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <span className="input-suffix">{/* Existing buttons */}</span>
        </span>
      </div>
      {/* <EmojiPicker /> */}
      {form?.loadingSendMessage ? (
        <Spinner animation="border" role="status" variant="warning">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <OverlayTrigger trigger="click" placement="top" overlay={popoverTop}>
          <button
            class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <span class="icon">
              <Smile />
            </span>
          </button>
        </OverlayTrigger>
      )}
    </footer>
  );
};

export default FooterChat;
