import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ReactLoading from "react-loading";
import BadgeSocial from "@atoms/Badge/social";
import ButtonSocial from "@atoms/Button/social";

import "moment/locale/pt-br";

import {
  getConversations,
  getConversation,
  resetConversation,
} from "@store/modules/interaction/actions";
import Image from "@atoms/Image/index";

moment.locale("pt-br");

const SidebarChat = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { form, conversations } = useSelector((state) => state.interaction);
  const [tab, setTab] = useState("OPENED");
  const [search, setSearch] = useState("");
  const [filterSource, setFilterSource] = useState(null);

  const handleGetConversation = (conversation) => {
    dispatch(getConversation(conversation));
  };

  const handleChangeTab = (tab) => {
    setTab(tab);
    setSearch("");
    dispatch(resetConversation());
  };

  const handleSourceFilterClick = (source) => {
    if (filterSource === source) {
      setFilterSource(null);
      return;
    }
    setFilterSource(source);
    console.log("Filtrar por source" + source);
  }

  const openedConversations = useMemo(() => {
    return conversations?.filter((c) => !c?.conversationCode);
  }, [conversations]);

  const closedConversations = useMemo(() => {
    return conversations?.filter((c) => c?.conversationCode);
  }, [conversations]);

  const filterConversations = (list, searchTerm, channelType) => {
    return list?.filter((c) => {
      let include = true;
      if (searchTerm) {
        include = c?.consumer?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase());
      }
      if (channelType) {
        include = include && c?.channelType === channelType;
      }
      return include;
    });
  };

  const finalConversations = useMemo(() => {
    return filterConversations(
      tab === "OPENED" ? openedConversations : closedConversations,
      search,
      filterSource
    );
  }, [tab, openedConversations, closedConversations, search, filterSource]);

  useEffect(() => {
    dispatch(getConversations());
  }, []);

  return (
    <div class="chatapp-aside">
      <header class="aside-header">
        <a class="chatapp-title">
          <h1>{t('Menções & Interações')}</h1>
        </a>

        <div class="d-flex"></div>
      </header>
      <div className="mt-4">
        <ul class="nav nav-light nav-pills nav-pills-rounded justify-content-center">
          <li class="nav-item" role="button">
            <a
              class={`nav-link ${tab === "OPENED" ? "active" : ""}`}
              onClick={() => handleChangeTab("OPENED")}
            >
              <span class="nav-link-text">{t('Abertos')}</span>
            </a>
          </li>
          <li class="nav-item" role="button">
            <a
              class={`nav-link ${tab === "CLOSED" ? "active" : ""}`}
              onClick={() => handleChangeTab("CLOSED")}
            >
              <span class="nav-link-text">{t('Fechados')}</span>
            </a>
          </li>
        </ul>

        <div className="d-flex justify-content-center p-3 pb-0">
          <input
            type="text"
            class="form-control w-90"
            placeholder={t("Pesquise um cliente...")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {['facebook', 'instagram', 'twitter'].map((source) => (
            <ButtonSocial 
              type={source} 
              selected={filterSource === source}
              onClick={() => handleSourceFilterClick(source)} 
            />
          ))}
        </div>
      </div>
      <div class="aside-body">
        {!form?.loadingConversations && (
          <ul class="chat-contacts-list list-group list-group-flush">
            {finalConversations?.map((conversation) => (
              <li
                class={`list-group-item ${
                  conversation?.parentId || conversation?._id
                }  ${
                  [conversation?.parentId, conversation?._id]
                    .filter((item) => item)
                    .includes(form?.selectedConversationId)
                    ? "active"
                    : ""
                }`}
                onClick={() => handleGetConversation(conversation)}
                key={conversation?._id + '-' + conversation?.consumer?._id}
              >
                <div class="media">
                  <div class="media-head">
                    <div class="avatar avatar-sm avatar-rounded position-relative">
                      <Image
                        src={conversation?.consumer?.photo}
                        alt="user"
                        class="avatar-img"
                      />
                      <BadgeSocial type={conversation?.channelType} />
                    </div>
                  </div>
                  <div class="media-body">
                    <div>
                      <div class="user-name" style={{ marginBottom: 0 }}>
                        {conversation?.consumer?.name}
                      </div>
                      <div class="user-last-chat">
                        {conversation?.senderType === "attendant" ? "eu: " : ""}
                        {conversation?.content}
                      </div>
                    </div>
                    <div>
                      <div class="last-chat-time">
                        {moment(conversation?.createdAt).fromNow()}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}

        {form?.loadingConversations && (
          <ReactLoading type="bars" height="10%" width="10%" color="#ffa722" />
        )}
      </div>
    </div>
  );
};

export default SidebarChat;
