import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";

import Routes from "@/routes";
import store from "@store";

import './i18n';

import "react-toastify/dist/ReactToastify.css";
import "./styles/custom.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Helmet htmlAttributes={{ translate: "no" }}>
      <meta name="google" content="notranslate" />
    </Helmet>
    <ToastContainer />
    <Routes />
  </Provider>
);
