import ReactLoading from "react-loading";
import util from "@/util";
import theme from "@styles/theme.json";

const LoaderPage = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        backgroundColor: util.toAlpha(theme.colors.light, 70),
        zIndex: 998,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ReactLoading type="spin" color={theme.colors.primary} width={50} />
      <h2>Carregando...</h2>
    </div>
  );
};

export default LoaderPage;
