import io from "socket.io-client";
const { REACT_APP_WS_ENDPOINT } = process.env;

const socket = io(REACT_APP_WS_ENDPOINT);

const connectToSocket = () => {
  return new Promise((resolve) => {
    socket.on("connect", () => {
      console.log("socket CONNECTED");
      resolve(socket);
    });

    socket.on("error", () => console.log("socket ERROR"));
    socket.on("disconnect", () => console.log("SOCKET disconnect"));
  });
};

const listenToEvent = (event, callback) => {
  socket.on(event, callback);
};

const removeEvent = (event, callback) => {
  socket.off(event, callback);
};

export { connectToSocket, listenToEvent, removeEvent };
