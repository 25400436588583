const types = {
  // FORM
  SET_FORM: "@attendant/SET_FORM",
  RESET_FORM: "@attendant/RESET_FORM",

  // AUTH
  LOGIN: "@attendant/LOGIN",
  LOGOUT: "@attendant/LOGOUT",

  // ATTENDANT
  SET_ATTENDANT: "@attendant/SET",
  RESET_ATTENDANT: "@attendant/RESET",
};

export default types;
