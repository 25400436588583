import InputMask from "react-input-mask";

const FormInputMask = (props) => {
  return (
    <div>
      {props.mask && (
        <InputMask
          onChange={(e) => props.handleOnChange(props.id, e.target.value)}
          {...props}
        >
          {(inputProps) => <input {...inputProps} />}
        </InputMask>
      )}
      {!props.mask && (
        <input
          onChange={(e) => props.handleOnChange(props.id, e.target.value)}
          {...props}
        />
      )}
    </div>
  );
};

export default FormInputMask;
