import DefaultTemplate from "@templates/DefaultTemplate";
import OrganismChat from "@organisms/Chat";

const PageInteractions = () => {
  return (
    <DefaultTemplate>
      <OrganismChat />
    </DefaultTemplate>
  );
};

export default PageInteractions;
