import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import ReactLoading from "react-loading";
import theme from "@styles/theme.json";

import { connectToSocket } from "@services/websocket";

import { login } from "@store/modules/attendant/actions";

const PageLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    form: { message, loadingLogin },
  } = useSelector((state) => state.attendant);

  const handleLogin = () => {
    const searchParams = new URLSearchParams(location.search);
    dispatch(login(searchParams.get("token")));
  };

  const checkLogged = () => {
    const attendant = sessionStorage.getItem("@attendant");
    if (attendant) history.replace("/interactions");
    else handleLogin();
  };

  const handleConnectSocket = () => {
    connectToSocket().then((socket) => {
      console.log("Connected to WebSocket");
    });
  };

  useEffect(() => {
    handleConnectSocket();
    checkLogged();
  }, []);

  return (
    <div
      class="hk-wrapper hk-pg-auth"
      data-footer="simple"
      style={{ background: "#121212" }}
    >
      <div class="hk-pg-wrapper pt-0 pb-xl-0 pb-5">
        <div class="hk-pg-body pt-0 pb-xl-0">
          <div class="container-xxl">
            <div class="row">
              <div class="col-sm-10 position-relative mx-auto">
                <div class="auth-content py-8">
                  <form class="w-100">
                    <div class="row">
                      <div class="col-lg-5 col-md-7 col-sm-10 mx-auto">
                        <div class="text-center mb-7">
                          <a class="navbar-brand me-0" href="index.html">
                            <img
                              class="brand-img d-inline-block"
                              src="https://postmetria.com.br/i/sem-nome-20060px.png"
                              alt="brand"
                            />
                          </a>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-content-center align-items-center gap-2">
                          {loadingLogin && (
                            <ReactLoading
                              type="spin"
                              color={theme.colors.primary}
                              width={50}
                            />
                          )}
                          <span>{message}</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hk-footer border-0" style={{ background: "#121212" }}>
          <footer class="container-xxl footer">
            <div class="row">
              <div class="col-xl-8 text-center">
                <p class="footer-text pb-0">
                  <span class="copy-text text-white">
                    Postmetria © {new Date().getFullYear()} Todos os direitos
                    reservados.
                  </span>
                </p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
