import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import Message from "@atoms/Message";

const DetailChat = () => {
  const messageListRef = useRef(null);

  const {
    conversation,
    conversations,
    form: { selectedConversationId },
  } = useSelector((state) => state.interaction);

  const selectedConversation = useMemo(() => {
    return conversations?.find((c) =>
      [c?.parentId, c?._id].includes(selectedConversationId)
    );
  }, [selectedConversationId, conversations]);

  return (
    <div data-simplebar id="chat_body" ref={messageListRef} class="chat-body">
      <ul id="conversation-list" class="list-unstyled chat-single-list">
        {conversation?.map((interaction) => (
          <Message
            interaction={interaction}
            conversation={selectedConversation}
          />
        ))}
      </ul>
    </div>
  );
};

export default DetailChat;
