import types from "./types";

// FORM
export function setForm(payload) {
  return { type: types.SET_FORM, payload };
}

export function resetForm() {
  return { type: types.RESET_FORM };
}

// RECORDS
export function getRecords() {
  return { type: types.GET_RECORDS };
}

export function setRecords(payload) {
  return { type: types.SET_RECORDS, payload };
}

export function saveRecords(payload) {
  return { type: types.SAVE_RECORDS, payload };
}

export function resetRecords() {
  return { type: types.RESET_RECORDS };
}
