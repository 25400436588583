import types from "./types";
import produce from "immer";

const INITIAL_STATE = {
  form: {
    loading: false,
    loadingSave: false,
  },
  records: {},
};

function interaction(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_FORM: {
      return produce(state, (draft) => {
        draft.form = { ...state.form, ...action.payload };
      });
    }
    case types.RESET_FORM: {
      return produce(state, (draft) => {
        draft.form = INITIAL_STATE.form;
      });
    }
    case types.SET_RECORDS: {
      return produce(state, (draft) => {
        draft.records = { ...state.records, ...action.payload };
      });
    }
    case types.RESET_RECORDS: {
      return produce(state, (draft) => {
        draft.records = INITIAL_STATE.records;
      });
    }
    default:
      return state;
  }
}

export default interaction;
