import moment from "moment";
import "moment/locale/pt-br";
import Image from "@atoms/Image/index";
import { useTranslation } from 'react-i18next';
import ImageWithFallback from "@atoms/ImageWithFallback/index";

moment.locale("pt-br");

const Message = ({ interaction, conversation }) => {
  const { t } = useTranslation();
  return (
    <>
      {interaction?.senderType === "consumer" && (
        <li
          className={
            "media received" + (interaction.conversationCode ? " saved" : "")
          }
        >
          <div class="avatar avatar-xs avatar-rounded">
            <Image
              src={conversation?.consumer?.photo}
              alt="user"
              class="avatar-img"
            />
          </div>
          <div class="media-body">
            <div class="msg-box">
              <div>
                {interaction?.isDeleted && (
                  <p className="text-muted"><i>{t('Mensagem apagada')}</i></p>
                )}
                {!interaction?.isDeleted && (
                  <>
                    {!!interaction?.attachments?.length &&
                      interaction.attachments.map((attachmentUrl, index) => {
                        const isVideo =
                          attachmentUrl.match(/\.(mp4|webm|ogg)/i);
                        return isVideo ? (
                          <video
                            className="chat-video"
                            key={attachmentUrl}
                            controls
                          >
                            <source src={attachmentUrl} type={`video/mp4`} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <ImageWithFallback
                            className="chat-image"
                            key={attachmentUrl}
                            src={attachmentUrl}
                            alt={`Attachment ${index + 1}`}
                          />
                        );
                      })}
                    <p>{interaction?.content}</p>
                  </>
                )}
                <span class="chat-time">
                  {moment(interaction?.createdAt).fromNow()}
                </span>
              </div>
            </div>
          </div>
        </li>
      )}

      {interaction?.senderType === "attendance" && (
        <li
          className={
            "media sent" + (interaction.conversationCode ? " saved" : "")
          }
        >
          <div className="media-body">
            <div className="msg-box">
              <div>
                {!!interaction?.attachments?.length &&
                  interaction.attachments.map((attachmentUrl, index) => {
                    const isVideo = attachmentUrl.match(/\.(mp4|webm|ogg)$/i);
                    return isVideo ? (
                      <video
                        className="chat-video"
                        key={attachmentUrl}
                        controls
                      >
                        <source
                          src={attachmentUrl}
                          type={`video/${attachmentUrl.split(".").pop()}`}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <ImageWithFallback
                        className="chat-image"
                        key={attachmentUrl}
                        src={attachmentUrl}
                        alt={`Attachment ${index + 1}`}
                      />
                    );
                  })}
                <p>{interaction?.content}</p>
                <span className="chat-time">
                  {moment(interaction?.createdAt).fromNow()}
                </span>
              </div>
            </div>
          </div>
        </li>
      )}
    </>
  );
};

export default Message;
