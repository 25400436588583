import { Facebook, Instagram, Twitter } from "react-feather";

const ButtonSocial = ({ type, size = 16, onClick, selected }) => {
  const BADGES_TYPES = {
    facebook: {
      icon: <Facebook size={size} />,
    },
    instagram: {
      icon: <Instagram size={size} />,
    },
    twitter: {
      icon: <Twitter size={size} />,
    },
  };

  return (
    <button className={`btn btn-icon ${selected ? "btn-link" : ""}`}
      onClick={onClick}
    >
      {BADGES_TYPES[type]?.icon}
    </button>
  );
};

export default ButtonSocial;
