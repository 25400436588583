import React, { useState } from "react";

const ImageWithFallback = ({ src, alt = "Image", ...props }) => {
  const [imageSrc, setImageSrc] = useState(src);
  const fallbackSrc = "https://i.imgur.com/ZZehqBF.png"; // Fallback image URL

  const handleError = () => {
    setImageSrc(fallbackSrc);
  };

  return <img src={imageSrc} onError={handleError} alt={alt} {...props} />;
};

export default ImageWithFallback;
