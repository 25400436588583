import { useState } from "react";
import placeholderImage from "@assets/placeholder.jpeg";

const Image = ({ src, ...props }) => {
  const [path, setPath] = useState(src);

  const handleOnError = () => {
    setPath(placeholderImage);
  };

  return <img {...props} src={path} onError={handleOnError} />;
};

export default Image;
