import { Route } from "react-router-dom";
import { ConnectedRouter as Router } from "connected-react-router";
import { history } from "@store";

import PageInteractions from "@pages/Interactions";
import PageLogin from "@pages/Login";

export default function App() {
  return (
    <Router history={history}>
      <Route path="/" exact component={PageLogin} />
      <Route path="/interactions" exact component={PageInteractions} />
    </Router>
  );
}
