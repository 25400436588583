import types from "./types";
import produce from "immer";

const INITIAL_STATE = {
  form: {
    loadingConversations: true,
    loadingConversation: false,
    loadingSendMessage: false,
    loadingCloseConversation: false,
    selectedConversationId: null,
    textMessage: "",
    modalCreateZendeskTicket: false,
  },
  zendeskTicket: {
    subject: "",
    priority: "high",
    type: "problem",
    closeConversation: true,
  },
  conversation: null,
  conversations: [],
  file: null,
};

function interaction(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_FORM: {
      return produce(state, (draft) => {
        draft.form = { ...state.form, ...action.payload };
      });
    }
    case types.ADD_CONVERSATION_EMOJI: {
      return produce(state, (draft) => {
        draft.form = {
          ...state.form,
          textMessage: `${state.form.textMessage}${action.emoji}`,
        };
      });
    }
    case types.RESET_CONVERSATION: {
      return produce(state, (draft) => {
        draft.conversation = INITIAL_STATE.conversation;
      });
    }
    case types.RESET_FORM: {
      return produce(state, (draft) => {
        draft.form = INITIAL_STATE.form;
      });
    }
    case types.SET_CONVERSATIONS: {
      return produce(state, (draft) => {
        draft.conversations = action.conversations;
      });
    }
    case types.SET_CONVERSATION: {
      return produce(state, (draft) => {
        draft.conversation = action.conversation;
      });
    }
    case types.UPDATE_CHAT: {
      return produce(state, (draft) => {
        /**
         * CONVERSATION LIST LOGIC
         */
        let conversations = [...draft.conversations];

        // procura e remove o item da lista
        const id = action.conversation?.parentId || action.conversation?._id;
        conversations = conversations.filter(
          (c) => ![c?._id, c?.parentId].includes(id),
        );

        // adiciona a conversa na primeira posição
        conversations.unshift(action.conversation);
        draft.conversations = conversations;

        /**
         * CONVERSATION SINGLE LOGIC
         */
        if (draft.form.selectedConversationId === id) {
          draft.conversation = [...draft.conversation, action.conversation];
        }
      });
    }
    case types.UPDATE_CONVERSATION: {
      return produce(state, (draft) => {
        const id = action.conversation._id;
        
        // update in conversations list
        const interactionIdx = draft.conversations.findIndex((c) => c?._id === id);
        if (interactionIdx !== -1) {
          draft.conversations[interactionIdx] = action.conversation;
        }
      
        // update in selected conversation, if it's the same
        if (draft.form.selectedConversationId === id || 
          draft.form.selectedConversationId === action.conversation.parentId
        ) {
          const interactionIdx = draft.conversation.findIndex((c) => c?._id === id);
          if (interactionIdx !== -1) {
            draft.conversation[interactionIdx] = action.conversation;
          }
        }
      });
    }
    case types.SET_ZENDESK_TICKET: {
      return produce(state, (draft) => {
        draft.zendeskTicket = { ...state.zendeskTicket, ...action.payload };
      });
    }
    case types.RESET_ZENDESK_TICKET: {
      return produce(state, (draft) => {
        draft.zendeskTicket = INITIAL_STATE.zendeskTicket;
      });
    }
    case types.SET_FILE: {
      return produce(state, (draft) => {
        draft.file = action.payload;
      });
    }
    default:
      return state;
  }
}

export default interaction;
