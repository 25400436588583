import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import attendant from "./attendant/reducer";
import interaction from "./interaction/reducer";
import form from "./form/reducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    attendant,
    interaction,
    form,
  });

export default createRootReducer;
