import { useState } from "react";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

function FormLocationPicker({ required, value }) {
  const [location, setLocation] = useState({
    country: null,
    state: null,
    city: null,
  });

  useState(() => {
    if (value) setLocation(value);
  }, [value]);

  const handleLocationChange = (payload) => {
    setLocation({
      ...location,
      ...payload,
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      {/* HIDDEN INPUTS TO CONTROL VALUE */}
      <input
        type="hidden"
        name="location"
        required={required}
        value={JSON.stringify(location)}
      />
      <CountrySelect
        defaultValue={location?.country}
        value={location?.country?.id}
        onChange={(country) => {
          handleLocationChange({ country });
        }}
        placeHolder="País"
      />
      <StateSelect
        countryid={location?.country?.id}
        onChange={(state) => {
          handleLocationChange({ state });
        }}
        placeHolder="Estado"
        defaultValue={location.state}
        value={location?.state?.id}
      />
      <CitySelect
        countryid={location?.country?.id}
        stateid={location?.state?.id}
        onChange={(city) => {
          handleLocationChange({ city });
        }}
        value={location?.city?.id}
        defaultValue={location?.city}
        placeHolder="Cidade"
      />
    </div>
  );
}

export default FormLocationPicker;
