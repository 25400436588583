// import Menu from "@molecules/Menu";
import Navbar from "@molecules/Navbar";
import PageLoader from "@molecules/Loader/page";

const DefaultLayout = ({ children, isLoading = false }) => {
  return (
    <div
      className="hk-wrapper"
      // change to 'vertical' and enable Menu when we have more pages
      // data-layout="vertical"
      data-layout="navbar"
      data-layout-style="collapsed"
      data-menu="light"
      data-footer="simple"
      data-hover="active"
    >
      <Navbar />
      {/* <Menu /> */}
      <div className="hk-pg-wrapper pb-0">
        {isLoading && <PageLoader />}
        <div className="hk-pg-body py-0">{children}</div>
      </div>
    </div>
  );
};

export default DefaultLayout;
