import types from "./types";

// FORM
export function setForm(payload) {
  return { type: types.SET_FORM, payload };
}

export function resetForm() {
  return { type: types.RESET_FORM };
}

// AUTH
export function login(jwtToken) {
  return { type: types.LOGIN, jwtToken };
}

export function logout() {
  return { type: types.LOGOUT };
}

// ATTENDANT
export function setAttendant(attendant) {
  return { type: types.SET_ATTENDANT, attendant };
}

export function resetAttendant() {
  return { type: types.RESET_ATTENDANT };
}
