import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";

import createRootReducer from "./modules/rootReducer";
import rootSaga from "./modules/rootSaga";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const middlewares = compose(
  applyMiddleware(sagaMiddleware),
  applyMiddleware(routerMiddleware(history))
);

const store = createStore(
  createRootReducer(history),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    ? composeWithDevTools(middlewares)
    : middlewares
);

sagaMiddleware.run(rootSaga);

export default store;
