const types = {
  // FORM
  SET_FORM: "@form/SET_FORM",
  RESET_FORM: "@form/RESET_FORM",

  // RECORDS
  GET_RECORDS: "@form/GET_RECORDS",
  SET_RECORDS: "@form/SET_RECORDS",
  SAVE_RECORDS: "@form/SAVE_RECORDS",
  RESET_RECORDS: "@form/RESET_RECORDS",
};

export default types;
