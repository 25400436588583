import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from './locales/en/translation.json';
import ptBrTranslation from './locales/pt-br/translation.json';

import moment from 'moment';
import 'moment/locale/pt-br';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      'pt-br': {
        translation: ptBrTranslation
      }
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

moment.locale(i18n.language);

export default i18n;