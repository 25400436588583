import { useEffect } from "react";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import LogoSymbol from "@assets/logo-symbol.png";
import LogoLetter from "@assets/logo-letter-dark.png";

import { logout, setAttendant } from "@store/modules/attendant/actions";

moment.tz.setDefault("America/Sao_Paulo");

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { attendant } = useSelector((state) => state.attendant);

  const checkLogged = async () => {
    const attendant = sessionStorage.getItem("@attendant");
    if (attendant) dispatch(setAttendant(JSON.parse(attendant)));
    else history.replace("/");
  };

  const handleLogout = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Tem certeza que deseja sair?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim, Sair.",
    });

    if (isConfirmed) dispatch(logout());
  };

  useEffect(() => {
    checkLogged();
  }, []);

  return (
    <>
      <nav className="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
        <div className="container-fluid">
          <div className="nav-start-wrap align-items-center">
            <a className="navbar-brand" href="/">
              <img
                className="brand-img img-fluid"
                style={{ width: 35 }}
                src={LogoSymbol}
                alt="brand"
              />
              <img
                className="brand-img img-fluid"
                src={LogoLetter}
                alt="brand"
                style={{ width: "60%" }}
              />
            </a>
          </div>
          <div className="nav-end-wrap">
            <ul className="navbar-nav flex-row">
              <li className="nav-item">
                <div className="dropdown ps-2">
                  <a
                    className=" dropdown-toggle no-caret"
                    href="#"
                    role="button"
                    data-bs-display="static"
                    data-bs-toggle="dropdown"
                    data-dropdown-animation
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <h6
                      className="d-inline-block"
                      style={{
                        marginRight: 10,
                      }}
                    >
                      {attendant?.company?.name}
                    </h6>
                    <div className="avatar avatar-primary avatar-xs avatar-rounded">
                      <span className="initial-wrap">
                        {attendant?.name?.[0]}
                      </span>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <div className="p-2">
                      <div className="media">
                        <div className="media-head me-2">
                          <div className="avatar avatar-primary avatar-sm avatar-rounded">
                            <span className="initial-wrap">
                              {attendant?.name?.[0]}
                            </span>
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="dropdown">
                            <a
                              href="#"
                              className="d-block dropdown-toggle link-dark fw-medium"
                              data-bs-toggle="dropdown"
                              data-dropdown-animation
                              data-bs-auto-close="inside"
                            >
                              {attendant?.name}
                            </a>
                          </div>
                          <div className="fs-7">{attendant?.email}</div>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={handleLogout}
                            className="d-block fs-8 link-secondary"
                          >
                            <u>Sair da conta</u>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
