import types from "./types";
import produce from "immer";

const INITIAL_STATE = {
  form: {
    loadingLogin: false,
    message: "Processando credenciais...",
  },
};

function attendant(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_FORM: {
      return produce(state, (draft) => {
        draft.form = { ...state.form, ...action.payload };
      });
    }
    case types.RESET_FORM: {
      return produce(state, (draft) => {
        draft.form = INITIAL_STATE.form;
      });
    }
    case types.SET_ATTENDANT: {
      return produce(state, (draft) => {
        draft.attendant = action.attendant;
      });
    }
    case types.RESET_ATTENDANT: {
      return produce(state, (draft) => {
        draft.attendant = INITIAL_STATE.attendant;
      });
    }
    default:
      return state;
  }
}

export default attendant;
