const types = {
  // FORM
  SET_FORM: "@interaction/SET_FORM",
  RESET_FORM: "@interaction/RESET_FORM",
  ADD_CONVERSATION_EMOJI: "@interaction/RESET_FORM",

  // CONVERSATIONS LIST
  GET_CONVERSATIONS: "@interaction/GET_CONVERSATIONS",
  SET_CONVERSATIONS: "@interaction/SET_CONVERSATIONS",

  // CONVERSATIONS LIST
  GET_CONVERSATION: "@interaction/GET_CONVERSATION",
  SET_CONVERSATION: "@interaction/SET_CONVERSATION",
  RESET_CONVERSATION: "@interaction/RESET_CONVERSATION",

  // WEBSOCKET
  UPDATE_CHAT: "@interaction/UPDATE_CHAT",
  UPDATE_CONVERSATION: "@interaction/UPDATE_CONVERSATION",

  // MESSAGE
  SEND_MESSAGE: "@interaction/SEND_MESSAGE",

  // CLOSE CONVERSATION
  CLOSE_CONVERSATION: "@interaction/CLOSE_CONVERSATION",

  // ZENDESK TICKET
  SET_ZENDESK_TICKET: "@interaction/SET_ZENDESK_TICKET",
  RESET_ZENDESK_TICKET: "@interaction/RESET_ZENDESK_TICKET",
  SAVE_ZENDESK_TICKET: "@interaction/SAVE_ZENDESK_TICKET",

  // FILE
  SET_FILE: "@interaction/SET_FILE",
};

export default types;
