import types from "./types";

// FORM
export function setForm(payload) {
  return { type: types.SET_FORM, payload };
}

export function resetForm() {
  return { type: types.RESET_FORM };
}

export function addConversationEmoji(emoji) {
  return { type: types.ADD_CONVERSATION_EMOJI, emoji };
}

// CONVERSATIONS LIST
export function getConversations() {
  return { type: types.GET_CONVERSATIONS };
}

export function setConversations(conversations) {
  return { type: types.SET_CONVERSATIONS, conversations };
}

// CONVERSATION SINGLE
export function getConversation(conversation) {
  return { type: types.GET_CONVERSATION, conversation };
}

export function setConversation(conversation) {
  return { type: types.SET_CONVERSATION, conversation };
}

export function resetConversation() {
  return { type: types.RESET_CONVERSATION };
}

// WEBSOCKET
export function updateChat(conversation) {
  return { type: types.UPDATE_CHAT, conversation };
}

export function updateConversation(conversation) {
  return { type: types.UPDATE_CONVERSATION, conversation };
}

// MESSAGE
export function sendMessage() {
  return { type: types.SEND_MESSAGE };
}

// CLOSE CONVERSATION
export function closeConversation() {
  return { type: types.CLOSE_CONVERSATION };
}

// ZENDESK TICKET
export function setZendeskTicket(payload) {
  return { type: types.SET_ZENDESK_TICKET, payload };
}

export function resetZendeskTicket() {
  return { type: types.RESET_ZENDESK_TICKET };
}

export function saveZendeskTicket() {
  return { type: types.SAVE_ZENDESK_TICKET };
}

// FILE
export function addFile(payload) {
  return { type: types.SET_FILE, payload };
}
