import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import Swal from "sweetalert2";
import ReactSwal from "react-sweetalert2";
import { Lock } from "react-feather";
import Image from "@atoms/Image";

import BadgeSocial from "@atoms/Badge/social";

import {
  closeConversation,
  setForm,
  setZendeskTicket,
  saveZendeskTicket,
} from "@store/modules/interaction/actions";

const HeaderChat = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { zendeskTicket, form } = useSelector((state) => state.interaction);

  const {
    conversations,
    form: { selectedConversationId, loadingCloseConversation },
  } = useSelector((state) => state.interaction);

  const INTERCTIONS_TYPES = {
    comment: t("Comentário em público da marca"),
    direct: t("Mensagem direta para marca"),
  };

  const selectedConversation = useMemo(() => {
    return conversations?.find((c) =>
      [c?.parentId, c?._id].includes(selectedConversationId)
    );
  }, [selectedConversationId, conversations]);

  const handleCloseConversation = async () => {
    const { isConfirmed } = await Swal.fire({
      icon: "warning",
      title: "Tem certeza que deseja fechar o atendimento?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim, fechar.",
    });

    if (isConfirmed) dispatch(closeConversation());
  };

  const handleSetZendeskTicket = (payload) => {
    dispatch(setZendeskTicket(payload));
  };

  const handleOpenZendToZendeskModal = () => {
    dispatch(setForm({ modalCreateZendeskTicket: true }));
  };

  const handleSendToZendesk = async ({ isConfirmed }) => {
    if (isConfirmed) dispatch(saveZendeskTicket());
    else dispatch(setForm({ modalCreateZendeskTicket: false }));
  };

  return (
    <>
      <ReactSwal
        icon="info"
        title="Criar ticket no Zendesk"
        text="Preencha os campos abaixo para criar o ticket no Zendesk."
        show={form?.modalCreateZendeskTicket}
        showCancelButton={true}
        cancelButtonText="Cancelar"
        confirmButtonText="Sim, criar."
        didClose={() => {
          dispatch(setForm({ modalCreateZendeskTicket: false }));
        }}
        onConfirm={handleSendToZendesk}
      >
        <div className="mt-5">
          <div className="form-group text-start">
            <label>Assunto</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o assunto do ticket"
              value={zendeskTicket?.subject}
              onChange={({ target: { value: subject } }) =>
                handleSetZendeskTicket({ subject })
              }
            />
          </div>

          <div className="d-flex w-100 gap-3">
            <div className="form-group text-start w-100">
              <label>Tipo</label>
              <select
                className="form-control"
                value={zendeskTicket?.type}
                onChange={({ target: { value: type } }) =>
                  handleSetZendeskTicket({ type })
                }
              >
                <option value="problem">Problema</option>
                <option value="incident">Incidente</option>
                <option value="question">Pergunta</option>
                <option value="task">Tarefa</option>
              </select>
            </div>
            <div className="form-group text-start w-100">
              <label>Prioridade</label>
              <select
                className="form-control"
                value={zendeskTicket?.priority}
                onChange={({ target: { value: priority } }) =>
                  handleSetZendeskTicket({ priority })
                }
              >
                <option value="high">Alta</option>
                <option value="normal">Normal</option>
                <option value="low">Baixa</option>
              </select>
            </div>
          </div>
          <div className="form-check text-start">
            <input
              id="zendeskTicketCloseConversationCheckbox"
              type="checkbox"
              className="mr-2 form-check-input"
              placeholder="Digite o assunto do ticket"
              checked={zendeskTicket?.closeConversation}
              onChange={() =>
                handleSetZendeskTicket({
                  closeConversation: !zendeskTicket?.closeConversation,
                })
              }
            />
            <label
              for="zendeskTicketCloseConversationCheckbox"
              className="form-check-label"
            >
              Fechar atendimento?
            </label>
          </div>
        </div>
      </ReactSwal>
      <header class="chat-header">
        <div class="media" style={{ minWidth: 400 }}>
          <div class="media-head">
            <div class="avatar avatar-sm avatar-rounded position-relative">
              <Image
                src={selectedConversation?.consumer?.photo}
                alt="user"
                class="avatar-img"
              />
              <BadgeSocial type={selectedConversation?.channelType} />
            </div>
          </div>
          <div class="media-body">
            <div class="user-name">
              {selectedConversation?.consumer?.name}
              {selectedConversation?.consumer?.username &&
                `- ${selectedConversation?.consumer?.username}`}
            </div>
            <div class="user-status">
              {INTERCTIONS_TYPES[selectedConversation?.type]}
            </div>
          </div>
        </div>
        <div class="chat-options-wrap">
          {!selectedConversation?.conversationCode && (
            <button
              disabled={loadingCloseConversation}
              className="btn btn-sm btn-primary"
              style={{ marginRight: 10 }}
              onClick={handleCloseConversation}
            >
              Fechar Atendimento
            </button>
          )}

          {selectedConversation?.conversationCode && (
            <button className="btn btn-sm btn-disabled d-flex items-center">
              <div
                style={{
                  marginRight: 5,
                }}
              >
                <Lock size={14} />
              </div>
              Fechado em{" "}
              {moment(selectedConversation?.updatedAt).format(
                "DD/MM/YYYY [às] HH:mm"
              )}
            </button>
          )}
          <button
            onClick={handleOpenZendToZendeskModal}
            disabled={loadingCloseConversation}
            className="btn btn-sm btn-success"
          >
            Enviar p/ Zendesk
          </button>
        </div>
      </header>
    </>
  );
};

export default HeaderChat;
