import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import { Clock } from "react-feather";

import SidebarChat from "@molecules/Sidebar/Chat";
import HeaderChat from "@molecules/Header/Chat";
import DetailChat from "@molecules/Detail/Chat";
import FooterChat from "@molecules/Footer/Chat";
import AsideChat from "@molecules/Aside/Chat";

import { listenToEvent } from "@services/websocket";
import { updateChat, updateConversation } from "@store/modules/interaction/actions";

import chatIllustration from "@assets/chat-empty.png";

const OrganismChat = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    form,
    conversation,
    conversations,
    form: { selectedConversationId },
  } = useSelector((state) => state.interaction);

  const selectedConversation = useMemo(() => {
    return conversations?.find((c) =>
      [c?.parentId, c?._id].includes(selectedConversationId)
    );
  }, [selectedConversationId, conversations]);

  const lastMessage = useMemo(() => {
    return conversation?.[conversation?.length - 1];
  }, [conversation]);

  const newInteractionListener = (data) => {
    dispatch(updateChat(data));
  };

  const updateInteractionListener = (data) => {
    dispatch(updateConversation(data));
  }

  const handleConnectSocket = () => {
    listenToEvent("new-interaction", newInteractionListener);
    listenToEvent("delete-interaction", updateInteractionListener);
  };

  useEffect(() => {
    handleConnectSocket();
  }, []);

  return (
    <div class="chatapp-wrap chatapp-info-active">
      <div class="chatapp-content">
        <SidebarChat />
        <div class="chatapp-single-chat">
          {!form?.loadingConversation && !conversation && (
            <div className="d-flex flex-column justify-content-center align-items-center w-100 mt-20">
              <img src={chatIllustration} style={{ width: 300 }} />
              <h3 className="mt-10 d-block">{t('Nenhuma conversa selecionada...')}</h3>
            </div>
          )}

          {form?.loadingConversation && (
            <div className="d-flex flex-column justify-content-center align-items-center w-100 mt-20">
              <ReactLoading
                type="bars"
                height="5%"
                width="5%"
                color="#ffa722"
              />
            </div>
          )}

          {!form?.loadingConversation && conversation && (
            <>
              <HeaderChat />
              <DetailChat />
              {!lastMessage?.conversationCode && <FooterChat />}
              {lastMessage?.conversationCode && (
                <div
                  style={{
                    width: "calc(100% - 319px)",
                    textAlign: "center",
                    background: "#fafafa",
                    padding: 20,
                  }}
                >
                  <Clock size={16} />
                  <text style={{ paddingLeft: 10 }}>
                    {t('A conversa durou')}{" "}
                    {moment
                      .utc(
                        moment
                          .duration(selectedConversation?.interactionDuration)
                          .asMilliseconds()
                      )
                      .format("HH[h] mm[m] ss[s]")}
                  </text>
                </div>
              )}
              <AsideChat />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganismChat;
