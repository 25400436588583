import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Download } from "react-feather";
import BadgeSocial from "@atoms/Badge/social";
import ReactLoading from "react-loading";

import FormInputMask from "@atoms/Form/inputMask";
import FormSelect from "@atoms/Form/select";
import FormLocationPicker from "@atoms/Form/locationPicker";

import {
  getRecords,
  saveRecords,
  setRecords,
} from "@store/modules/form/actions";

import util from "@/util";
import Image from "@atoms/Image/index";

const AsideChat = () => {
  const dispatch = useDispatch();

  const {
    conversations,
    conversation,
    form: { selectedConversationId, loadingCloseConversation },
  } = useSelector((state) => state.interaction);

  const {
    attendant: { company },
  } = useSelector((state) => state.attendant);

  const {
    form: { loading, loadingSave },
    records,
  } = useSelector((state) => state.form);

  const selectedConversation = useMemo(() => {
    return conversations?.find((c) =>
      [c?.parentId, c?._id].includes(selectedConversationId)
    );
  }, [selectedConversationId, conversations]);

  const getFormComponent = (
    {
      id,
      type = "text",
      component,
      label,
      multiple,
      mask,
      required,
      options = [],
    },
    value,
    disabled,
    handleOnChange = (key, value) => {}
  ) => {
    const genericProps = {
      id,
      name: id,
      className: "form-control",
      placeholder: label,
      required,
      value,
      disabled,
      handleOnChange,
    };

    // input
    if (component === "input")
      return <FormInputMask mask={mask} type={type} {...genericProps} />;

    // text area
    if (component === "textarea")
      return (
        <textarea
          onChange={(e) => handleOnChange(id, e.target.value)}
          rows={3}
          {...genericProps}
        ></textarea>
      );

    // select
    if (component === "select")
      return (
        <FormSelect multiple={multiple} {...genericProps} options={options} />
      );

    // location-picker
    if (component === "location-picker")
      return <FormLocationPicker {...genericProps} />;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formValues = {};

    formData.forEach((value, key) => {
      if (formValues[key]) {
        formValues[key] = Array.isArray(formValues[key])
          ? [...formValues[key], value]
          : [formValues[key], value];
      } else {
        formValues[key] = util.isJsonString(value) ? JSON.parse(value) : value;
      }
    });

    const records = company?.consumerForm?.map((formItem) => ({
      ...formItem,
      value: formValues[formItem?.id],
    }));

    dispatch(saveRecords(records));
    return false;
  };

  const handleOnComponentChange = (key, value) => {
    dispatch(setRecords({ [key]: value }));
  };

  useEffect(() => {
    if (conversation?.length) {
      dispatch(getRecords());
    }
  }, [conversation]);

  return (
    <form class="chat-info" onSubmit={onFormSubmit}>
      {!loading && (
        <div data-simplebar class="nicescroll-bar">
          <button type="button" class="info-close btn-close">
            <span aria-hidden="true">×</span>
          </button>
          <div class="text-center">
            <div class="avatar avatar-xxl avatar-rounded">
              <Image
                src={selectedConversation?.consumer?.photo}
                alt="user"
                class="avatar-img"
              />
              <BadgeSocial size={12} type={selectedConversation?.channelType} />
            </div>
            <div class="cp-name text-truncate mt-2">
              <p class="text-truncate align-center">
                {selectedConversation?.consumer?.name}
              </p>
              {/* <div class="input-group mb-3">
                <input
                  name="fullname"
                  className="form-control text-center"
                  value={selectedConversation?.consumer?.name}
                  disabled={loadingSave}
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-success"
                    type="button"
                    disabled={loadingSave}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    Atualizar
                  </button>
                </div> 
              </div>*/}
            </div>
            <p class="text-truncate">
              {selectedConversation?.consumer?.username}
            </p>
          </div>
          <div class="tab-content mt-4">
            <div class="tab-pane fade show active" id="tab_info">
              <div class="collapse-simple mt-3">
                <div class="card">
                  <div class="card-header">
                    <a
                      role="button"
                      data-bs-toggle="collapse"
                      href="#gn_info"
                      aria-expanded="true"
                    >
                      Informações Gerais
                    </a>
                  </div>
                  <div id="gn_info" class="collapse show">
                    <div class="card-body">
                      <ul class="cp-info">
                        {company?.consumerForm?.map((formItem) => (
                          <li>
                            <small
                              className={formItem?.required ? "text-red" : ""}
                            >
                              {formItem?.label} {formItem?.required && "*"}
                            </small>
                            {getFormComponent(
                              formItem,
                              records[formItem?.id],
                              loadingSave || loadingCloseConversation,
                              handleOnComponentChange
                            )}
                          </li>
                        ))}
                      </ul>

                      <button
                        disabled={loadingSave || loadingCloseConversation}
                        type="submit"
                        className="btn btn-success btn-block"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div class="card">
                <div class="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#biography"
                    aria-expanded="true"
                  >
                    Anexos
                  </a>
                </div>
                <div id="biography" class="collapse show">
                  <div class="card-body">
                    <ul class="cp-files">
                      <li>
                        <div class="media">
                          <div class="media-head">
                            <div class="avatar avatar-icon avatar-sm avatar-soft-blue">
                              <span class="initial-wrap fs-3">
                                <i class="ri-file-excel-2-fill"></i>
                              </span>
                            </div>
                          </div>
                          <div class="media-body">
                            <div>
                              <p class="file-name">Lista de instruções.doc</p>
                              <p class="file-size">2,635 KB</p>
                            </div>
                            <div>
                              <a class="btn btn-md btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret">
                                <span class="icon">
                                  <Download size={10} />
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {loading && (
        <div className="d-flex justify-content-center mt-10">
          <ReactLoading type="bars" height="10%" width="10%" color="#ffa722" />
        </div>
      )}
    </form>
  );
};

export default AsideChat;
