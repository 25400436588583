import { takeLatest, all, call, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "@services/api";
import types from "./types";
import { setForm, setRecords, resetRecords } from "./actions";

export function* getRecords() {
  try {
    yield put(setForm({ loading: true }));

    const {
      attendant: { companyId },
    } = yield select((state) => state.attendant);
    const { conversation } = yield select((state) => state.interaction);

    const { _id: parentInteractionId, consumerId } = conversation[0];

    const { data: res } = yield call(api.post, `/form/filter`, {
      companyId,
      consumerId,
      parentInteractionId,
    });

    if (res.error) {
      toast.error(`Ops... ${res.message}`);
      return false;
    }

    yield put(resetRecords());
    yield put(setRecords(res));
  } catch (err) {
    toast.error(`Erro interno! ${err.message}`);
  } finally {
    yield put(setForm({ loading: false }));
  }
}

export function* saveRecords({ payload }) {
  try {
    yield put(setForm({ loadingSave: true }));

    const {
      attendant: { companyId },
    } = yield select((state) => state.attendant);
    const { conversation } = yield select((state) => state.interaction);

    const { _id: parentInteractionId, consumerId } = conversation[0];

    const { data: res } = yield call(api.post, `/form`, {
      companyId,
      consumerId,
      parentInteractionId,
      records: payload,
    });

    if (res.error) {
      toast.error(`Ops... ${res.message}`);
      return false;
    }

    toast.success("Registros salvos com sucesso!");
  } catch (err) {
    toast.error(`Erro interno! ${err.message}`);
  } finally {
    yield put(setForm({ loadingSave: false }));
  }
}

export default all([
  takeLatest(types.GET_RECORDS, getRecords),
  takeLatest(types.SAVE_RECORDS, saveRecords),
]);
