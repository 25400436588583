import { Facebook, Instagram, Twitter } from "react-feather";

const BadgeSocial = ({ type, size = 9 }) => {
  const BADGES_TYPES = {
    facebook: {
      icon: <Facebook size={size} />,
      color: "#316FF6",
    },
    instagram: {
      icon: <Instagram size={size} />,
      color: "#962fbf",
    },
    twitter: {
      icon: <Twitter size={size} />,
      color: "#1DA1F2",
    },
  };

  return (
    <span
      class="badge position-bottom-end-overflow-1"
      style={{
        borderRadius: "100%",
        minWidth: "unset",
        width: size * 2,
        height: size * 2,
        padding: "5%",
        background: BADGES_TYPES[type]?.color,
      }}
    >
      {BADGES_TYPES[type]?.icon}
    </span>
  );
};

export default BadgeSocial;
