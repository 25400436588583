import { takeLatest, all, call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import api from "@services/api";
import types from "./types";
import { setForm, resetForm, setAttendant, resetAttendant } from "./actions";

export function* login({ jwtToken }) {
  try {
    yield put(setForm({ loadingLogin: true }));

    const { data: res } = yield call(api.post, `/api/sso`, {
      token: jwtToken,
    });

    if (res.error) {
      toast.error(`Ops... ${res.message}`);
      yield put(
        setForm({
          message: "Aconteceu um erro. Feche essa aba e tente novamente.",
          loadingLogin: false,
        })
      );
      return false;
    }

    sessionStorage.setItem("@attendant", JSON.stringify(res.data));
    yield put(resetForm());
    yield put(setAttendant(res.data));
    yield put(push("/interactions"));
  } catch (err) {
    toast.error(`Erro interno! ${err.message}`);
  } finally {
    yield put(setForm({ loadingLogin: false }));
  }
}

export function* logout() {
  try {
    localStorage.removeItem("@attendant");
    yield put(resetForm());
    yield put(resetAttendant());
    yield put(push("/"));
  } catch (err) {
    toast.error(`Erro interno! ${err.message}`);
  } finally {
    yield put(setForm({ loadingLogin: false }));
  }
}

export default all([
  takeLatest(types.LOGIN, login),
  takeLatest(types.LOGOUT, logout),
]);
