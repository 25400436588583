import { takeLatest, all, call, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "@services/api";
import types from "./types";
import {
  setForm,
  setConversations,
  setConversation,
  getConversations as getConversationsAction,
  resetZendeskTicket,
  addFile,
} from "./actions";

export function* getConversations() {
  try {
    yield put(setForm({ loadingConversations: true }));

    /**
     * -> GET CONVERSATIONS LIST
     * -> SET CONVERSATIONS LIST
     */

    const {
      attendant: { company },
    } = yield select((state) => state.attendant);
    const { data: res } = yield call(
      api.get,
      `/interaction/company/${company?.pstmExternalId}`
    );

    if (res.error) {
      toast.error(`Ops... ${res.message}`);
      return false;
    }

    yield put(setConversations(res.data));
  } catch (err) {
    toast.error(`Erro interno! ${err.message}`);
  } finally {
    yield put(setForm({ loadingConversations: false }));
  }
}

export function* getConversation({ conversation }) {
  try {
    const conversationId = conversation?.parentId || conversation?._id;
    const { consumer, channelId, type, conversationCode } = conversation;

    yield put(setForm({ loadingConversation: true }));

    /**
     * -> GET SINGLE CONVERSATION
     * -> SET SINGLE CONVERSATION
     */

    const { data: res } = yield call(api.post, `/interaction/conversation`, {
      parentId: conversationId,
      consumerId: consumer?._id,
      channelId,
      type,
      isStrict: !!conversationCode,
    });

    if (res.error) {
      toast.error(`Ops... ${res.message}`);
      return false;
    }

    yield put(setForm({ selectedConversationId: conversationId }));
    yield put(setConversation(res.data));
  } catch (err) {
    toast.error(`Erro interno! ${err.message}`);
  } finally {
    yield put(setForm({ loadingConversation: false }));
  }
}

export function* sendMessage() {
  try {
    yield put(setForm({ loadingSendMessage: true }));

    const { form, conversation, file } = yield select(
      (state) => state.interaction
    );
    const { attendant } = yield select((state) => state.attendant);

    // By default, reply in the last comment/message
    const parentInteraction = conversation[conversation.length - 1];

    // Preparing FormData for file upload
    const formData = new FormData();
    formData.append("parentId", parentInteraction?._id);
    formData.append("companyId", attendant?.companyId);
    formData.append("consumerId", parentInteraction?.consumerId);
    formData.append("senderId", attendant?._id);
    formData.append("channelId", parentInteraction?.channelId);
    formData.append("type", parentInteraction?.type);
    formData.append("content", form?.textMessage);

    // Append file if present
    if (file) {
      console.log(`file is present`);
      formData.append("file", file);
    }

    // Making the API call with FormData
    const { data: res } = yield call(
      api.post,
      `/interaction/message`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (res.error) {
      toast.error(`Ops... ${res.message}`);
      return false;
    }

    yield put(setForm({ textMessage: "" }));
  } catch (err) {
    toast.error(`Erro interno! ${err.message}`);
  } finally {
    yield put(addFile(""));
    yield put(setForm({ loadingSendMessage: false }));
  }
}

export function* closeConversation() {
  try {
    yield put(setForm({ loadingCloseConversation: true }));

    const { conversation } = yield select((state) => state.interaction);

    /**
     * AWAYS THE FIRST ITEM WITH DON'T HAVE CONVERSATIONCODE
     */
    const parentInteraction = conversation?.filter(
      (c) => !c?.conversationCode
    )?.[0];

    const { data: res } = yield call(api.put, `/interaction/close`, {
      parentInteractionId: parentInteraction?._id,
      consumerId: parentInteraction?.consumerId,
      channelId: parentInteraction?.channelId,
    });

    if (res.error) {
      toast.error(`Ops... ${res.message}`);
      return false;
    }

    // RESET CURRENT CONVERSATION
    yield put(setConversation(null));
    // RELOAD MESSAGES
    yield put(getConversationsAction());

    toast.success(`Atendimento fechado com sucesso!`);
  } catch (err) {
    toast.error(`Erro interno! ${err.message}`);
  } finally {
    yield put(setForm({ loadingCloseConversation: false }));
  }
}

export function* saveZendeskTicket() {
  try {
    yield put(setForm({ loadingCloseConversation: true }));

    const { conversation, zendeskTicket } = yield select(
      (state) => state.interaction
    );

    /**
     * AWAYS THE FIRST ITEM (THAT IS NOT SAVED)
     */
    const parentInteraction = conversation?.filter(
      (c) => !c?.conversationCode
    )?.[0];

    const { data: res } = yield call(api.post, `/interaction/zendesk`, {
      parentInteractionId: parentInteraction?._id,
      consumerId: parentInteraction?.consumerId,
      channelId: parentInteraction?.channelId,
      ...zendeskTicket,
    });

    if (res.error) {
      toast.error(`Ops... ${res.message}`);
      return false;
    }

    if (zendeskTicket?.closeConversation) {
      // RESET CURRENT CONVERSATION
      yield put(setConversation(null));
    }

    // RELOAD MESSAGES
    yield put(getConversationsAction());

    // RESET ZENDESK FORM
    yield put(resetZendeskTicket());

    toast.success(`Ticket criado no Zendesk!`);
  } catch (err) {
    toast.error(`Erro interno! ${err.message}`);
  } finally {
    yield put(setForm({ loadingCloseConversation: false }));
  }
}

export default all([
  takeLatest(types.GET_CONVERSATIONS, getConversations),
  takeLatest(types.GET_CONVERSATION, getConversation),
  takeLatest(types.SEND_MESSAGE, sendMessage),
  takeLatest(types.CLOSE_CONVERSATION, closeConversation),
  takeLatest(types.SAVE_ZENDESK_TICKET, saveZendeskTicket),
]);
